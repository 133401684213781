import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import EventCard from "components/EventCard";
import { getAllEventByOrganizer } from "api";

const OrganizersEvents = props => {
  //meta title
  document.title = "";
  const [eventList, setEventList] = useState([])

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await getAllEventByOrganizer();
        console.log("🚀 ~ fetchEvents ~ events:", events)
        setEventList(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
    return () => {
      // Cleanup code here
    };
  }, []);
 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Events")}
          />
          <Row>
          {eventList.map(event => (
            <Col lg="3" key={event.slugId}>
              <EventCard event={event} />
            </Col>
          ))
          }
            
          </Row>
           
           
        </Container>
      </div>
    </React.Fragment>
  );
};

OrganizersEvents.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(OrganizersEvents);
