import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import moment from 'moment';

const EventCard = ({event}) => {

    console.log("🚀 ~ EventCard ~ data:", event)
    return (
        <>
            <Card className="p-1 border shadow-none">
                <div className="p-3">
                    <h5>
                        <Link to="#" className="text-dark">
                           {event?.eventName}
                        </Link>
                    </h5>
                    <p className="text-muted mb-0">  {`${moment(event?.eventDate).format('DD MMMM, YYYY HH:mm')}`}</p>
                </div>

                <div className="position-relative">
                    <img
                        src={"https://eventox.co/_next/static/media/pakka_local_thumb.7dcef92f.png"}
                        alt=""
                        className="img-thumbnail"
                    />
                </div>

                <div className="p-3">
                    <ul className="list-inline">
                        <li className="list-inline-item me-3">
                            <Link to="#" className="text-muted">
                                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                {event?.eventLocation}
                            </Link>
                        </li>
                       
                    </ul>
                    <p>
                       {event?.shortDescription}
                    </p>
{/* 
                    <div>
                        <Link to="#" className="text-primary">
                            Read more{" "}
                            <i className="mdi mdi-arrow-right"></i>
                        </Link>
                    </div> */}
                </div>
            </Card>

        </>
    )
}

export default EventCard